const template = require('lodash/template');

const loadRemote = template(
  `promise new Promise(async (resolve, reject) => {
      try {
        const module = await import('<%= url %>');
        resolve(module);
      } catch (error) {
        // console.error('Failed to load remote:', error);
        resolve(null);
      }
    })
`,
  { variable: 'url' }
);

const remotes = [
  {
    name: 'WhiteboardCareJournal',
    host: process.env.REACT_APP_WHITEBOARD_CAREJOURNAL_HOST,
    entryName: '/assets/remoteEntry.js',
  },
  {
    name: 'SNCareJournal',
    host: process.env.REACT_APP_CAREJOURNAL_HOST,
    entryName: '/assets/remoteEntry.js',
  },
];

const setupRemotes = () => {
  const modules = remotes.reduce((acc, { name, host, entryName }) => {
    acc[name] = loadRemote(`${host}${entryName}?t=${new Date().getTime()}`);
    return acc;
  }, {});
  return modules;
};

module.exports = { setupRemotes, remotes };
