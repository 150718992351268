import React from 'react';
import { Box, Grid } from '@material-ui/core';
import Text from './Text';

export default function FallbackComponent() {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Grid container className="w3-center">
        <Grid item xs={12}>
          <Text h2>Oops! Something went wrong.</Text>
        </Grid>
        <Grid item xs={12} className="w3-margin-top">
          <Text>Please refresh the page or try again later</Text>
        </Grid>
        <Grid item xs={12} className="w3-margin-top ">
          <Text>Contact the support team if problem still persists</Text>
        </Grid>
      </Grid>
    </Box>
  );
}
