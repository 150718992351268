import { lazy } from 'react';
import DatadogHandler from 'utils/datadog';
import FallbackComponent from '../components/common/FallbackComponent';

const { remotes } = require('../mfe.config');

export default function loadRemote({
  module = '',
  loadModule = () => Promise.reject(new Error('Missing loadModule')),
  hideFreshChat = true,
}) {
  const Fallback = {
    default: FallbackComponent,
  };

  if (hideFreshChat && window.fcWidget) {
    window.fcWidget.hide?.();
    window.fcWidget.destroy?.();
  }

  return lazy(async () => {
    try {
      const remote = remotes.find(r => r.name === module);
      const valid = await fetch(`${remote.host}${remote.entryName}`, {
        method: 'GET',
      });
      if (!valid.ok) return Fallback;

      return loadModule();
    } catch (err) {
      DatadogHandler.sendLog(err, {}, 'error');
      return Fallback;
    }
  });
}
